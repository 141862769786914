body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #17345a;
  height: 100vh;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100vh;
  color: rgb(244, 242, 239);
  overflow-x: hidden;
}

.App-blurred {
  height: 100vh;
  background-color: #17345a;
  color: rgb(244, 242, 239);
  filter: blur(4px);
  overflow: hidden;
}

.App-blurred-dark {
  height: 100vh;
  background-color: black;
  color: rgb(244, 242, 239);
  filter: blur(4px);
  overflow: hidden;
}

.nav-bar {
  display: none;
}

/* Nav-bar style */

.sticky-bar {
  position: fixed;
  top: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 2fr 1fr;
  text-align: center;
  justify-content: center;
  z-index: 1;
}

.sticky-bar-side-menu {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}

.sticky-bar-dark {
  position: fixed;
  top: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 2fr 1fr;
  text-align: center;
  justify-content: center;
  height: fit-content;
  z-index: 1;
}

.nav-options-parent {
  padding: 15px;
  font-weight: 400;
  letter-spacing: 3px;
  margin: 0px;
  backdrop-filter: blur(6px);
  height: fit-content;
  background: rgba(0, 0, 0, 0.246);
}

.nav-options {
  margin-left: 15%;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  height: fit-content;
}

.option-desktop {
  margin-right: 5%;
  text-decoration: none;
  color: antiquewhite;
  overflow-wrap: normal;
  height: fit-content;
}

.option-desktop:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.166);
}

.toggle-parent {
  margin-right: 5%;
  text-align: left;
  padding: 15px;
  font-size: 15px;
  background: rgba(0, 0, 0, 0.246);
  backdrop-filter: blur(6px);
}

.toggles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10%;
  height: 25.05;
}

.lang-div {
  height: fit-content;
}

.language {
  height: 25.05px;
  border-style: unset;
  border-radius: 5px;
  color: white;
  background: rgba(0, 0, 0, 0.246);
  backdrop-filter: blur(6px);
  margin: 0;
}

.toggle-mode {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border-radius: 5px;
}

.hamburger-parent {
  display: none;
}

/* Body style */

.body {
  margin-top: 200px;
  font-size: 20px;
  letter-spacing: 5px;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.body-dark {
  margin-top: 200px;
  font-size: 20px;
  background-color: black;
  letter-spacing: 5px;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.brief-description {
  margin-top: 120px;
}

.myImage {
  border-radius: 50%;
  height: 300px;
  width: 300px;
}

.text {
  margin-top: -40px;
  backdrop-filter: blur(5px);
}

.separator {
  align-self: center;
  margin-top: 100px;
  width: 100%;
  border-bottom: 2px solid white;
}

/* Work style */

.work {
  font-size: 20px;
  letter-spacing: 5px;
  margin-top: 200px;
  height: fit-content;
  margin-left: 10%;
  margin-right: 10%;
}

.work-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-dark {
  background-color: black;
  font-size: 20px;
  letter-spacing: 5px;
  margin-top: 200px;
  height: fit-content;
  margin-left: 10%;
  margin-right: 10%;
}

.work-title {
  width: fit-content;
  align-self: flex-start;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 150px;
}

.cardImage {
  height: 10rem;
  width: 10rem;
  padding: 5px;
}
.graphics {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.logos {
  display: flex;
  flex-direction: row;
  text-align: center;
}
.logo {
  margin-bottom: 5px;
  width: 25px;
}
.projectName {
  color: aqua;
  margin-top: 10px;
  font-size: 18px;
}
.projectDescription {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.CardContainer {
  background-color: #0b1a2e;
  border: 1px solid white;
  border-radius: 10px;
  text-align: left;
  margin: 0 10px;
  padding: 5%;
}

/* project slider */

.slider {
  margin-left: 25%;
  margin-right: 25%;
  width: 70vw;
}


/* customation of Carousel component */
.rec.rec-arrow {
  background-color: #17345a;
  color: grey;
}

.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled {
  background-color: #1d5195;
  box-shadow: 0 0 1px 3px white;
}

button.rec-dot {
  background-color: #1d5195;
  box-shadow: 0 0 1px 3px #1d5195;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px white;
}

/* Myself style */

.myself {
  font-size: 30px;
  letter-spacing: 5px;
  align-items: center;
  margin-top: 200px;
  margin-left: 10%;
  margin-right: 10%;
}

.myself-dark {
  background-color: black;
  font-size: 30px;
  letter-spacing: 5px;
  align-items: center;
  margin-top: 200px;
  margin-left: 10%;
  margin-right: 10%;
}

.myself-body {
  width: 100%;
}

.container {
  max-width: 600px;
  margin: none;
  font-family: Arial, sans-serif;
  line-height: 1.5;
  font-size: 18px;
  color: rgb(244, 242, 239);
  margin-bottom: 20px;
  justify-content: center;
}

.event {
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.event h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  color: rgb(244, 242, 239);
}

.event p {
  margin-bottom: 0;
  overflow: hidden;
}

.event .details {
  display: none;
  position: relative;
  top: 100%;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  margin-top: 20px;
}

.event .active {
  height: auto;
}

.details {
  transition: opacity 3s ease-in-out;
}

.event.active .details {
  display: block;
  color: #333;
}

/*Contact page style*/

#form-div {
  color: white;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  letter-spacing: 5px;
  align-items: center;
}

.form-title {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 50px;
}

.promt-message {
  display: flex;
  flex-direction: column;
  text-align: left;
  letter-spacing: 3px;
}

.promt-message-dark {
  background-color: black;
  display: flex;
  flex-direction: column;
  text-align: left;
  letter-spacing: 3px;
}

.formulario {
  margin-top: 100px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 50px;
  letter-spacing: 3px;
}

.formulario-dark {
  margin-top: 100px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 50px;
  background-image: linear-gradient(to right, #0a0b0e, rgba(41, 8, 8, 0.768));
  letter-spacing: 3px;
}

label {
  width: 200px;
  font-size: 20px;
}

input {
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 20px;
}

.message-input {
  display: flex;
  justify-content: left;
}

#file {
  margin-bottom: 0;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  text-align: center;
  border-style: none;
  text-align: center;
  background-color: #8dbf42b6;
  font-size: 20px;
  color: #fff;
  padding: 10px;
  margin: 0;
}

.delete-button {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  width: 20%;
  background-color: #bf4242b6;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border-style: none;
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.form-button {
  display: block;
  margin: auto;
  margin-top: 25px;
  font-size: 20px;
  background-color: #811f34;
  text-decoration: none;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

#overlayed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

#overlay-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #17345a;
  pointer-events: none;
}

#overlay-active-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  pointer-events: none;
}

#success-msg {
  color: white;
  opacity: 0;
}

#success-msg-active {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 40px;
  opacity: 1;
}
.footer {
  width: 100%;
  text-align: center;
}
.footer-content {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

@media (max-width: 400px) {
  .separator {
    width: 100vw;
    margin-top: 40px;
  }
  .sticky-bar {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100px;
    backdrop-filter: blur(6px);
    background: rgba(0, 0, 0, 0.246);
  }

  .sticky-bar-dark {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100px;
    background: rgba(0, 0, 0, 0.176);
    backdrop-filter: blur(6px);
  }
  /* show the nav options when screen width is at least 768px */
  .nav-options-parent {
    display: none; /* hide the nav options by default */
  }
  .toggle-parent {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    padding: 0;
    font-size: 10px;
    width: 75%;
    background: none;
    backdrop-filter: none;
  }
  .toggles {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 10px;
    background: none;
    backdrop-filter: none;
  }
  .toggle-mode {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 40px;
    margin-right: 0;
    margin: 5px;
  }
  .language {
    text-align: center;
    font-size: 18px;
    height: 25px;
    width: 65px;
  }
  .lang-div {
    margin-bottom: 5px;
  }
  .hamburger-parent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .side-menu {
    position: absolute;
    width: 50%;
    margin-top: -20px;
    height: 100vh;
    background-color: #1d5195;
    z-index: 1;
    right: 0;
  }
  .side-menu-dark {
    position: absolute;
    width: 50%;
    margin-top: -20px;
    height: 100vh;
    background-color: black;
    border-left: 1px solid white;
    z-index: 1;
    right: 0;
  }
  .side-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 25%;
    gap: 30px;
    margin-top: 50%;
  }
  .option {
    text-decoration: none;
    color: antiquewhite;
    font-size: 20px;
  }
  /* show the body options when screen width is at least 768px */

  .body {
    margin-top: 200px;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .body-dark {
    margin-top: 200px;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .brief-description {
    width: 100%;
    margin-top: 130px;
    align-items: center;
    text-align: center;
  }
  .myImage {
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }
  .text {
    margin-top: 40px;
  }
  /* show the Work options when screen width is at least 768px */
  .work-body {
    width: 100vw;
    margin-top: 130px;
    margin-left: 5px;
  }
  .work {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .work-dark {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .work-title {
    width: 100vw;
    margin-left: 10px;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 80px;
  }
  .slider {
    text-align: center;
    margin-left:0;
    margin-right: 25px;
    height: 50%;
    width: 100%;
    
  }
  .CardContainer {
    height: 50%;
    width: 100%;
  }
  .cardImage {
    height: 10rem;
    width: 10rem;
    padding: 5px;
  }
  .graphics {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .logos {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .logo {
    margin-bottom: 5px;
    width: 25px;
  }
  .projectName {
    color: aqua;
    margin-top: 10px;
    font-size: 18px;
  }
  .projectDescription {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }
  /* show the Myself section when screen width is at least 768px */
  .myself {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    letter-spacing: 5px;
    align-items: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .myself-dark {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    letter-spacing: 5px;
    align-items: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .myself-body {
    width: 100vw;
    margin-top: 130px;
  }
  .container {
    font-size: 16px;
  }
  .event {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .event h2 {
    font-size: 20px;
  }
  .event .details {
    position: static;
    display: none;
    margin-top: 10px;
    opacity: 1;
    border-radius: 5px;
    border-top: 2px solid #ccc;
  }
  .event.active .details {
    display: block;
  }
  .form-div {
    width: 100vw;
    margin-top: 130px;
    padding: 10px;
  }
  .promt-message {
    font-size: 20px;
  }
  .promt-message-dark {
    font-size: 20px;
  }
  .form-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }
  .formulario {
    width: 100vw;
    background-image: linear-gradient(to right, #0b1a2e, #17345a);
    border: none;
    margin-top: 0px;
  }

  .formulario-dark {
    width: 100vw;
    background-image: linear-gradient(to right, #0a0b0e, rgba(41, 8, 8, 0.768));
    border: none;
    margin-top: 0px;
  }
  .success-wrapper {
    margin-left: 15%;
    margin-right: 15%;
  }
  .success-wrapper-dark {
    margin-left: 15%;
    margin-right: 15%;
  }
  #success-msg-active {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 20px;
    opacity: 1;
  }
  label {
    font-size: 20px;
    width: 100%;
  }

  input {
    width: 100%;
  }

  #file {
    margin-bottom: 0;
    width: 100%;
  }

  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
    text-align: center;
    border-style: none;
    text-align: center;
    background-color: #8dbf42b6;
    font-size: 20px;
    color: #fff;
    padding: 10px;
    margin: 0;
  }

  .delete-button {
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    width: 20%;
    background-color: #bf4242b6;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    border-style: none;
    margin-bottom: 20px;
  }

  .form-button {
    padding: 5px;
    font-size: 20px;
    border-radius: 5px;
    background-color: #811f34;
    text-decoration: none;
  }
  textarea {
    font-size: 15px;
  }
  .sun {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-left: 15px;
  }
  .moon {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-left: 6px;
  }
}
