.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .modal-content {
    position: relative;
    display: grid;
    grid-template-rows: 0.5fr 2fr ;
    font-weight: 100;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    padding: 20px;
    background-color: rgb(14, 21, 41);
    border-radius: 10px;
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .modal-content-mobile {
    display: none;
  }

  .item-name {
    height: fit-content;
    margin-bottom: 2%;
  }

  .item-texts {
    color: yellow;
    font-weight: 300;
  }

  .item-props {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .name {
    color: aqua;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .others {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: bold;
    margin-top: 15px;
    text-decoration: none;
  }

  .svg {
    width: 10px;
    height: 10px;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .close-modal::before,
  .close-modal::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 2px;
    background-color: white;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .close-modal::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  @media (max-width: 800px) {
    .modal-content {
      display: none;
    }
    .modal-content-mobile {
      position: relative;
      display: grid;
      grid-template-rows: 0.5fr 1fr 1.5fr  ;
      font-weight: 100;
      max-width: 90%;
      max-height: 90%;
      overflow-y: auto;
      padding: 10px;
      background-color: rgb(14, 21, 41);
      border-radius: 10px;
      color: white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
    .item-name-mobile {
      height: fit-content;
      margin-bottom: 2%;
    }
    .name-mobile {
      color: aqua;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      font-size: 18px;
    }
    .item-texts {
      color: yellow;
      font-weight: 300;
    }
    .others {
      font-size: 13px;
      color: white;
    }
    .item-imgs{
      display: flex;
      flex-direction: row;
      margin-top: 15px;
    }

  }